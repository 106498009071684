import gql from 'graphql-tag';

import { IS_PREVIEW } from '@src/typescriptGlobals/constants';
import { GQL_PAGE_ID_TYPES } from '@src/typescriptGlobals/contentful.types';

import { GQL_KNOWLEDGE_BASE_PAGE_HERO_COMPONENT_ENTRY } from './pageQueries/knowledgeBasePageQueries';

export const GQL_DEFAULT_METADATA_ENTRIES = gql`
  query ($locale: String!, $slug: String!, $preview: Boolean!) {
    page: pageCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: $locale, slug: $slug }
      preview: $preview
    ) {
      items {
        languageCode
        seoMetadata {
          title
          description
          previewImage {
            url
          }
          index
          follow
        }
      }
    }
  }
`;

// =============================================
//              SITE MAPS QUERY
// =============================================
export const GQL_FETCH_SITEMAPS_DATA = `
  query {
    postCollection(where: {contentfulMetadata: { tags: {id_contains_some: ["vp"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        sys {
          publishedAt
        }
        translationsCollection (where: {sys: {id_exists: true}}) {
          items {
            languageCode
            slug
          }
        }
      }
    }
    pageCollection(where: {contentfulMetadata: { tags: {id_contains_some: ["vp"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`;

// =============================================
//              CANONICAL QUERY
// =============================================
export const GQL_FETCH_CANONICAL_DATA = `
  query {
    postCollection( where: {contentfulMetadata: { tags: {id_contains_some: ["vp"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        seoMetadata {
            canonical
          }
        translationsCollection (limit:10, where: {sys: {id_exists: true}}) {
          items {
            languageCode
            slug
            seoMetadata {
              canonical
            }
          }
        }
      }
    }
    pageCollection(where: {contentfulMetadata: { tags: {id_contains_some: ["vp"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        seoMetadata {
          canonical
        }
      }
    }
  }
`;

// =============================================
//                 LANDING PAGE QUERIES
// =============================================
export const GQL_FETCH_LANDING_PAGE_METADATA = `
  query($pageId: String!) {
    page(id: $pageId, preview: ${IS_PREVIEW}) {
      seoMetadata {
        title
        description
        previewImage {
          url
        }
        index
        follow
      }
    }
  }
`;

export const GQL_DEFAULT_PAGE_FIELDS = `
  items {
    sys {
    publishedAt
    firstPublishedAt
  }
  slug
  pageName
  seoMetadata {
    ... on SeoMetaTags {
      description
      previewImage {
        url
      }
    }
  }
`;

export const GQL_FETCH_KNOWLEDGE_BASE_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.HOME_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      ${GQL_KNOWLEDGE_BASE_PAGE_HERO_COMPONENT_ENTRY}
    }
  }
`;

// =============================================
//             TOP NAVIGATION QUERY
// =============================================

export const GQL_FETCH_TOP_NAVIGATION_ENTRY = gql`
  query ($locale: String!, $preview: Boolean!) {
    topNavigation: topNavigationCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: $locale }
      preview: $preview
    ) {
      items {
        logo {
          url
          width
          height
          description
        }
        navItemsCollection(order: order_ASC) {
          items {
            sys {
              id
            }
            slug
            pageName
            subPagesCollection(order: order_ASC) {
              items {
                sys {
                  id
                }
                slug
                pageName
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_GET_POST_METADATA_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vp"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        seoMetadata {
          ... on SeoMetaTags {
            title
            description
            previewImage {
              url
            }
            index
            follow
            canonical
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_POST_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vp"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        slug
        title
        categoriesCollection(limit: 1) {
          items {
            title
            slug
          }
        }
        thumbnail {
          url
        }
        sys {
          id
          publishedAt
          firstPublishedAt
        }
        datePublished
        featuredImage {
          url
          width
          height
          title
          description
          contentType
        }
        languageCode
        translationsCollection(limit: 20) {
          items {
            sys {
                  id
                }
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 20) {
            items {
              sys {
                id
              }
              languageCode
              title
              slug
              translationsCollection(limit: 20) {
                items {
                  sys {
                    id
                  }
                  languageCode
                  slug
                }
              }
            }
          }
        }
        body {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                url
                title
                width
                height
                contentType
                description
              }
            }
            entries {
              __typename
              inline {
                sys {
                  id
                }
                __typename
                ... on Image {
                  title
                  alignment
                  width
                  url
                  media {
                    url
                    height
                    width
                    description
                  }
                }
                ... on BlogComponent {
                  componentType
                  mergedCellTable
                }
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on TextEditor {
                  tinyMce
                }
                ... on Button {
                  text
                  url
                  type
                  target
                }
                ... on Image {
                  media {
                    url
                    width
                    height
                    description
                  }
                  alignment
                  width
                  url
                  bodyCopy {
                    json
                  }
                }
                ... on Video {
                  type
                  url
                  description
                }
                ... on BlogComponent {
                  componentType
                  textBoxColor
                  mergedCellTable
                  bodyCopy {
                    json
                    links {
                      entries {
                        block {
                          sys {
                            id
                          }
                          ... on Image {
                            alignment
                            width
                            url
                            media {
                              url
                              description
                              width
                              height
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on DuplexComponent {
                  reversed
                  title
                  bodyCopy {
                    json
                  }
                  mediaPrimary {
                    url
                    width
                    height
                    description
                  }
                }
                ... on Grid {
                  sys {
                    id
                  }
                  carousel
                  scrollTo
                  title
                  bodyCopy {
                          json
                        }
                  topicCollection {
                    items {
                      ... on Card {
                        mediaPrimary {
                          width
                          height
                          url
                          description
                        }
                        mediaSecondary {
                          width
                          height
                          url
                          description
                        }
                        title
                        slug
                        bodyCopy {
                          json
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_POST_ENTRY_SLUGS = gql`
  query {
    postCollection(where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }) {
      items {
        slug
        languageCode
      }
    }
  }
`;

export const GQL_FETCH_TOTAL_POST_ENTRY = gql`
  query ($locale: String!, $preview: Boolean!) {
    postCollection(
      preview: $preview
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
    ) {
      total
    }
  }
`;

export const GQL_GET_ANNOUNCEMENT_BANNER_ENTRY = `
  query {
     grid(id:"6da7iWp1YXeudskcl3mD7G"){
      title
      topicCollection {
        items {
          ... on Card {
            link
            preTitle
            title
          }
        }
      }
    }
  }
`;

// =============================================
//             TOP ANNOUCEMENT BAR QUERY
// =============================================
export const GQL_FETCH_ANNOUNCEMENT_BAR = `
  query {
    eBooks: grid(id:"6da7iWp1YXeudskcl3mD7G"){
      title
      bodyCopy {
          json
        }
      topicCollection {
        items {
          ... on Card {
            link
            preTitle
            title
            bodyCopy {
              json
            }
          }
        }
      }
    }
  }
`;
